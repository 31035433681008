/* You can add global styles to this file, and also import other style files */

/* Search Form CSS */

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
html {
    scroll-behavior: smooth;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .search-button>a {
        margin: 0 40px;
    }
    .search-form-area #search {
        width: 450px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .search-button>a {
        margin: 15px 0;
        display: block;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .search-button>a {
        margin: 15px 0;
        display: block;
    }
}

.search-form-area {
    width: auto;
    height: 30px;
    display: none;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}

/* .search .fa-search { 
    position: absolute;
     top: 24px;
    left: 7px; 
    font-size: 15px;
  } */

.search-form-open .search-form-area {
    display: block !important;
}

.search-form-open .navbar-nav {
    display: none !important;
}

.search-form-area #search {
    width: 550px;
    height: 30px;
    border-radius: 50px;
    border: 2px solid rgba(255, 255, 255, 0.7);
    border-color: #040452;
    padding: 0 30px;
    color: #000 !important;
    font-size: 14px;
    background-color: transparent;
    margin-top: 5px;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}

/* Back to top button */

.back-to-top {
    position: fixed;
    display: none;
    background: #fab268;
    color: #fff;
    padding: 6px 12px 9px 12px;
    font-size: 16px;
    border-radius: 2px;
    left: 15px;
    bottom: 15px;
    transition: background 0.5s;
}

@media (max-width: 768px) {
    .back-to-top {
        bottom: 15px;
    }
}

.back-to-top:focus {
    background: #fab268;
    color: #fff;
    outline: none;
}

.back-to-top:hover {
    background: #0c2e8a;
    color: #fff;
}

.team-img {
    border-radius: 30%;
}

/*Cookie Consent Begin*/

#cookieConsent {
    background-color: #777777;
    min-height: 26px;
    font-size: 14px;
    color: #fff;
    line-height: 26px;
    padding: 8px 0 8px 30px;
    font-family: "Trebuchet MS", Helvetica, sans-serif;
    position: fixed;
    bottom: 0;
    left: 15%;
    right: 15%;
    display: none;
    z-index: 9999;
}

#cookieConsent a {
    color: #fab268;
    text-decoration: none;
}

#closeCookieConsent {
    float: right;
    display: inline-block;
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin: -15px 0 0 0;
    font-weight: bold;
}

#closeCookieConsent:hover {
    color: #FFF;
}

#cookieConsent a.cookieConsentOK {
    background-color: #fab268;
    color: #000;
    display: inline-block;
    border-radius: 5px;
    padding: 0 20px;
    cursor: pointer;
    float: right;
    margin: 0 60px 0 10px;
}

#cookieConsent a.cookieConsentOK:hover {
    background-color: #E0C91F;
}

/*Cookie Consent End*/